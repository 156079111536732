import {Component, Input} from '@angular/core';

export interface JsonLabel {
  label: string;
  text: string;
}

@Component({
  selector: 'ae-json',
  templateUrl: './json-view.component.html',
  styleUrls: ['./json-view.component.scss'],
})
export class JsonViewComponent {
  jsonInput: any = {};
  jsonOutput: JsonLabel[] = [];

  @Input() set json(json: any) {
    if(json) {
      this.jsonInput = json;
      this.processJson();
    }
  };

  processJson() {
    let output:JsonLabel[] = [];
    for (let key in this.jsonInput) {
      const val = this.jsonInput[key];
      const item = this.processValue(key, val);
      output = output.concat(item);
    }
    this.jsonOutput = output;
  }

  processValue(key: any, val: any): any[] {
    const parsedValue = this.tryParseJson(val);
    const tp = typeof parsedValue;

    if (tp === 'object') {
      let output: any[] = [];
      for (let subKey in parsedValue) {
        const subVal = parsedValue[subKey];
        output = output.concat(this.processValue(subKey, subVal));
      }
      return output;
    } else {
      return [{label: key, text: parsedValue}];
    }
  }

  tryParseJson(jsonValue: any): any {
    try {
      return JSON.parse(jsonValue);
    } catch (e:any) {
      return jsonValue.toString();
    }
  }
}
