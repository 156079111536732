<ae-nav></ae-nav>

<div>
  <div class="search-filter">
    <ae-search-filter class="search-filter"
                      [searchTitle]="'FeaturePrints Search'"
                      [includeDataSetSearch]="true"
                      [includeDateSearch]="true"
                      [includeLabelSearch]="true"
                      [dataSetList]="datasetNames"
                      [statusList]="lookups.statusList"
                      (searchEvent)="searchHandler($event)"
                      (resetSearchEvent)="resetSearchHandler()"
    >
    </ae-search-filter>
  </div>

  <div #stockItemTop class="search-filter">
    <mat-card *ngIf="stockItems && stockItems.length > 0">
      <div class="header-menu">
        <h3 class="header-menu-title">FeaturePrints Count: {{ searchResultsTotal || 0 }}</h3>
        <button *ngIf="currentStockItem" mat-raised-button (click)="clearCurrent();">
          Clear Selection
        </button>
      </div>

      <div class="scroll-pane scroll-pane-all"
           [ngClass]="{'scroll-pane-detail': currentStockItem}">
        <table class="table table-sm table-hover table-striped">
          <thead>
          <tr>
            <th>Label</th>
            <th>Maison</th>
            <th>Enabled Facets</th>
            <th>Created</th>
            <th>Last Facet Created</th>
            <th>Station</th>
            <th>User</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let r of stockItems; index as i" #stockItemList
              [ngClass]="{'selected': currentStockItem == r.stockItemId}"
              class="link-active"
              (click)="currentStockItem == r.stockItemId ? clearCurrent() : viewCapture(i, r.stockItemId)"
          >
            <td>
              <a class="link-active" matTooltip="click to open FeaturePrint in new tab"
                 target="_blank"
                 [routerLink]="['/featurePrint', this.searchDataset, r.stockItemId]"> {{ r.label | ellipsis:30 }} </a>
            </td>
            <td matTooltip="click row for details">{{ r.maisonCode }}</td>
            <td matTooltip="click row for details">{{ r.facetCount }}</td>
            <td matTooltip="click row for details">{{ r.created | date: "yyyy-MM-dd HH:mm:ss" }}</td>
            <td matTooltip="click row for details">{{ r.maxFacetCreated | date: "yyyy-MM-dd HH:mm:ss" }}</td>
            <td matTooltip="click row for details">{{ r.stationId }}</td>
            <td matTooltip="click row for details">{{ r.author }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </mat-card>
    <mat-paginator #paginator
                   [length]="searchResultsTotal || 0"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions"
                   [showFirstLastButtons]="true"
                   [hidden]="searchResultsTotal < 1"
                   (page)="pageHandler($event)"
    ></mat-paginator>
    <mat-card *ngIf="stockItems &&  stockItems.length === 0" class="no-match">
      <mat-card-content>
        No Matching Records
      </mat-card-content>
    </mat-card>

    <ae-featureprint *ngIf="currentStockItem"
                   [stockItemSelector]="selectedStockItem"
                   [fullView]="false"
                   (stockItemChange)="registrationChangeHandler($event)">
    </ae-featureprint>
  </div>
</div>
